import React, { useContext } from "react";
import { Route, Routes } from "react-router-dom";
import { ThemeContext } from "./components/themes/ThemeContext";
import { LanguageContext } from "./components/languages/LanguageContext";
import { IntlProvider } from "react-intl";
import messages from "./components/languages/messages";
// import component
import Topbar from "./components/topbar/Topbar";
// import FR
import HomeFR from "./pages/home/Home-fr";
import About from "./pages/about/About";
import Contact from "./pages/contact/Contact";
import Error from "./pages/error/Error";
import Project from "./pages/project/Project";
import Skills from "./pages/skill/Skills";
import Game from "./pages/game/Game";
// import EN
import HomeEN from "./pages/home/Home-en";
// import css
import "./assets/boxicons-2.1.4/css/boxicons.min.css";
import "./App.css";

function App() {
  const theme = useContext(ThemeContext);
  const darkMode = theme.state.darkMode;
  const locale = useContext(LanguageContext);
  const langue = locale.state.language;

  const Home = langue ? <HomeFR /> : <HomeEN />;

  return (
    <IntlProvider
      locale={langue ? "fr" : "en"}
      messages={messages[langue ? "fr" : "en"]}
    >
      <div className={darkMode ? "dark-mode" : ""}>
        <div className="App">
          <Topbar />
          <Routes>
            <Route path="/profil" element={<About />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/projets" element={<Project />} />
            <Route path="/compétences" element={<Skills />} />
            <Route path="/game" element={<Game />} />
            <Route path="/" element={Home} />
            <Route path="*" element={<Error />} />
          </Routes>
        </div>
      </div>
    </IntlProvider>
  );
}

export default App;
