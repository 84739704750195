import React from "react";
import { TagCloud } from "@frank-mayer/react-tag-cloud";
import { FormattedMessage } from "react-intl";
import './skills.css';
// import { useNavigate } from "react-router-dom";
import Rocket from '../../assets/pictures/rocket.png';

export default function Skills() {

    document.title = "Noé Jais | Compétences";
    // let navigate = useNavigate();

    return (
        <div className="skills">
            <div className="skills-container">
                <div className="skills-title"><FormattedMessage id={"programming"} description={"programming"} /></div>
                <div className="skills-sphere">
                    <TagCloud
                        options={(w: Window & typeof globalThis): TagCloudOptions => ({
                            radius: Math.min(500, w.innerWidth, w.innerHeight) / 2,
                            maxSpeed: "normal",
                        })}
                    >
                        {[
                            "HTML",
                            "CSS",
                            "React",
                            "NodeJS",
                            "JavaScript",
                            "Python",
                            "C",
                            "C++",
                            "C#",
                            ".Net",
                            "Haskell",
                            "GIT",
                            "GITHUB",
                            "GITLAB",
                        ]}
                    </TagCloud>
                </div>
                <div className="skills-styled">
                    <div className="skills-item-left"><img className="skill-img" src={Rocket} alt="https://fr.vecteezy.com/png-gratuit/fus%C3%A9e" /></div>
                    <div className="skills-item-right"></div>
                </div>
            </div>
        </div>
    );
}