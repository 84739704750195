export default function parralax() {
  const elements = document.querySelectorAll(".home-one-element");

  // Debounce function to throttle the event listener
  const debounce = (func, delay) => {
    let timeoutId;
    return function (...args) {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => func.apply(this, args), delay);
    };
  };

  const handleMouseMove = debounce((e) => {
    const mouseX = e.clientX;
    const mouseY = e.clientY;
    const screenWidth = window.innerWidth;
    const screenHeight = window.innerHeight - 70; // Subtract 70px for the height of your top bar

    elements.forEach((element) => {
      const dx = element.offsetLeft + element.offsetWidth / 2 - mouseX;
      const dy = element.offsetTop + element.offsetHeight / 2 - mouseY;
      const distance = Math.sqrt(dx * dx + dy * dy);
      const maxDistance = 300;
      const displacement = maxDistance - (distance / maxDistance) * 100;
      let translateX = (dx / maxDistance) * displacement;
      let translateY = (dy / maxDistance) * displacement;

      // Check if element is going off screen horizontally
      if (element.offsetLeft + translateX < 0) {
        translateX = -element.offsetLeft;
      } else if (
        element.offsetLeft + translateX + element.offsetWidth >
        screenWidth
      ) {
        translateX = screenWidth - element.offsetLeft - element.offsetWidth;
      }

      // Check if element is going off screen vertically
      if (element.offsetTop + translateY < 70) {
        // adjust 70 to the height of your top bar
        translateY = -element.offsetTop + 70; // adjust 70 to the height of your top bar
      } else if (
        element.offsetTop + translateY + element.offsetHeight >
        screenHeight
      ) {
        translateY = screenHeight - element.offsetTop - element.offsetHeight;
      }

      element.style.transform = `translate(${translateX}px, ${translateY}px)`;
    });
  }, 10); // Adjust the delay (in milliseconds) as per your needs

  document.addEventListener("mousemove", handleMouseMove);

  return () => {
    document.removeEventListener("mousemove", handleMouseMove);
  };
}
