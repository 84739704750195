import React, { useState } from "react";
import { collection, addDoc } from "@firebase/firestore";
import { db } from "../firebase/FirebaseSetup";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { FormattedMessage } from "react-intl";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const ContactForm = () => {
  const [name, setName] = useState("");
  const [surname, setSurName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [open, setOpen] = React.useState(false);

  const SubmitForm = (name, surname, email, subject, message) => {
    const dbRef = collection(db, "contacts");

    let data = {
      name: name,
      surname: surname,
      email: email,
      subject: subject,
      message: message,
    };

    addDoc(dbRef, data)
      .then((docRef) => {
        console.log("Document has been added successfully");
        setOpen(true);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission logic here (e.g., send data to server)
    SubmitForm(name, surname, email, subject, message);
    // Reset form fields
    setName("");
    setSurName("");
    setEmail("");
    setSubject("");
    setMessage("");
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  return (
    <div>
      <div>
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <Alert
            onClose={handleClose}
            severity="success"
            sx={{ width: "100%" }}
          >
            <FormattedMessage
              id={"contact_alert"}
              description={"contact_alert"}
            />
          </Alert>
        </Snackbar>
      </div>
      <form onSubmit={handleSubmit} className="contact-form">
        <div className="contact-form-title">
          <FormattedMessage
            id={"contact_form_title"}
            description={"contact_form_title"}
          />
        </div>
        <div className="contact-form-subtitle">
          <FormattedMessage
            id={"contact_form_subtitle1"}
            description={"contact_form_subtitle1"}
          />
          <br />
          <FormattedMessage
            id={"contact_form_subtitle2"}
            description={"contact_form_subtitle2"}
          />
        </div>

        <div className="contact-form-label">
          <div className="form-field">
            <input
              type="text"
              id="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="Surname"
              className="form-input"
              required={true}
            />
          </div>
          <div style={{ marginRight: "10px" }}></div>
          <div className="form-field">
            <input
              type="text"
              id="surname"
              value={surname}
              onChange={(e) => setSurName(e.target.value)}
              placeholder="Name"
              className="form-input"
              required={true}
            />
          </div>
        </div>

        <input
          type="email"
          id="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Email"
          className="form-input"
          required={true}
        />

        <input
          type="text"
          id="Sujet"
          value={subject}
          onChange={(e) => setSubject(e.target.value)}
          placeholder="Subject"
          className="form-input"
          required={true}
        />

        <textarea
          id="message"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          placeholder="Message"
          className="form-textarea"
          required={true}
        ></textarea>

        <button type="submit" className="form-button">
          <FormattedMessage
            id={"contact_form_send"}
            description={"contact_form_send"}
          />
        </button>
      </form>
    </div>
  );
};

export default ContactForm;
