import './project.css';
import React, { useContext } from "react";
import { FormattedMessage } from "react-intl";
import { LanguageContext } from "../../components/languages/LanguageContext";

import smshield from "../../assets/pictures/smshield-project.png";
import sneakers from "../../assets/pictures/sneakers-oz.png";
import css from "../../assets/pictures/tech/css.png";
import html from "../../assets/pictures/tech/html.png";
import python from "../../assets/pictures/tech/python.png";
import node from "../../assets/pictures/tech/node.png";
import typescript from "../../assets/pictures/tech/typescript.png";
import react from "../../assets/pictures/tech/react.png";


const project_fr = [
    {
        "id": 1,
        "title": "SM Shield",
        "description": "SM Shield est la plateforme de référence en matière de détection et de lutte contre le cyber-harcèlement.",
        "technologies": [react, node, typescript, python, css, html],
        "picture": smshield,
        "link": "https://main.d37v00imnt2422.amplifyapp.com/vitrine"
    },
    {
        "id": 2,
        "title": "Sneakers OZ",
        "description": "Sneakers OZ est une boutique en ligne qui vend des vêtements et des chaussures en édition très limitée.",
        "technologies": [react, node, css, html],
        "picture": sneakers,
    }
]

const project_en = [
    {
        "id": 1,
        "title": "SM Shield",
        "description": "SM Shield is the benchmark platform for detecting and combating cyber-bullying.",
        "technologies": [react, node, typescript, python, css, html],
        "picture": smshield,
        "link": "https://main.d37v00imnt2422.amplifyapp.com/vitrine"
    },
    {
        "id": 2,
        "title": "Sneakers OZ",
        "description": "Sneakers OZ is an online boutique selling very limited edition clothing and shoes.",
        "technologies": [react, node, css, html],
        "picture": sneakers,
    }
]

function displayProject(data) {
    return (
        <div style={{ marginTop: "20px" }}>
            {data.map((project) => {

                const openProject = () => {
                    window.open((project.link), "_blank");
                };

                return (
                    <div key={project.id} className='project-background'>
                        <div className="project-column-left" >
                            <div className='project-subtitle'>{project.title}</div>
                            <div className='project-description'>{project.description}</div>
                            <div className='project-technologies'>Technologies:</div>
                            <div className='project-technologies-container'>{project.technologies.map((tech) => {
                                return (
                                    <img className="project-technologies-icon" src={tech} alt="technologies" />
                                )
                            })}</div>
                            {project.link ? <div className='project-link' onClick={() => openProject()}><FormattedMessage id={"see"} description={"see"} /> {project.title} <i className='bx bx-sm bx-show' style={{ marginLeft: '8px' }} /></div> : <div />}
                        </div>
                        <div className="project-column-right" >
                            <div className="project-picture-container">
                                <img src={project.picture} alt={project.title} />
                            </div>
                        </div>
                    </div>
                );
            })}
        </div >
    );
}

export default function Project() {
    document.title = "Noé Jais | Projets";

    const locale = useContext(LanguageContext);
    const langue = locale.state.language;

    return (
        <div className="project">
            <div className="project-container">
                <div className="project-header">
                    <div className="project-title"><FormattedMessage id={"project_title"} description={"project_title"} /></div>
                </div>
                <div className="project-display">{langue ? displayProject(project_fr) : displayProject(project_en)}</div>
            </div>
        </div>
    );
}