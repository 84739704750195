const messages = {
  en: {
    language: "English",
    greeting: "Hello",
    goodbye: "Goodbye",
    welcome: "Welcome!",
    thankYou: "Thank you!",
    error: "An error has occurred.",
    loading: "Loading...",
    success: "Success!",
    confirm: "Are you sure you want to do this?",
    cancel: "Cancel",
    yes: "Yes",
    no: "No",
    submit: "Submit",
    edit: "Edit",
    delete: "Delete",
    search: "Search",
    next: "Next",
    previous: "Previous",
    settings: "Settings",
    signOut: "Sign out",
    see: "See",
    bachelor: "Degree in Information Technology",
    master: "Master in information technology",
    exchange: "Academic exchange",
    programming: "My programming languages",
    dark: "Dark",
    light: "Light",

    // Home page :
    home: "Home",

    // Profile page :
    profile: "Profile",

    // Skills page :
    skill: "Skills",

    // Project page :
    project: "Projects",
    project_title: "I design and develop websites",

    // Contact page :
    contact: "Contact",
    contact_title: "The various ways to",
    contact_title_colored: "contact me",
    contact_form_title: "A project or a request?",
    contact_form_subtitle1:
      "I would be delighted to answer any questions you may have.",
    contact_form_subtitle2: "To do so, please fill in this form.",
    contact_form_send: "Submit",
    contact_alert: "your message has been sent!",

    // Error page
    error_msg:
      "The page you are looking for may have been deleted, changed its name or is temporarily unavailable.",
    error_btn: "Home",

    // Game
    game_title: "Under Construction",
    game_subtitle: "This page is not available, please come back later",
  },
  fr: {
    language: "French",
    greeting: "Bonjour",
    goodbye: "Au revoir",
    welcome: "Bienvenue !",
    thankYou: "Merci !",
    error: "Une erreur est survenue.",
    loading: "Chargement...",
    success: "Succès !",
    confirm: "Êtes-vous sûr(e) de vouloir faire cela ?",
    cancel: "Annuler",
    yes: "Oui",
    no: "Non",
    submit: "Envoyer",
    edit: "Modifier",
    delete: "Supprimer",
    search: "Rechercher",
    next: "Suivant",
    previous: "Précédent",
    settings: "Réglages",
    signOut: "Déconnexion",
    see: "Voir",
    bachelor: "Bachelor en technologies de l'information",
    master: "Expert en technologies de l'information",
    exchange: "Echange universitaire",
    programming: "Mes langages de programmation",
    dark: "Sombre",
    light: "Clair",

    // Home page :
    home: "Accueil",

    // Profile page :
    profile: "Profil",

    // Skills page :
    skill: "Compétences",

    // Project page :
    project: "Projets",
    project_title: "Je conçois et développe des sites web",

    // Contact page :
    contact: "Contact",
    contact_title: "Les divers moyens de me",
    contact_title_colored: "contacter",
    contact_form_title: "Un Projet, une Demande ?",
    contact_form_subtitle1:
      "Je serais ravi de pouvoir répondre à toutes vos demandes.",
    contact_form_subtitle2: "Pour cela, veuillez remplir ce formulaire.",
    contact_form_send: "Envoyer",
    contact_alert: "votre message a bien été envoyé !",

    // Error page
    error_msg:
      "La page que vous recherchez a peut-être été supprimée, a changé de nom ou est temporairement indisponible.",
    error_btn: "Accueil",

    // Game
    game_title: "En construction",
    game_subtitle: "Cette page n'est pas disponible, revenez plus tard",
  },
};

export default messages;
