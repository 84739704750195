import React from 'react';
import './home.css';
import '../skill/skills.css';

// Import page
import Contact from '../contact/Contact';
import Project from '../project/Project';
import Skills from '../skill/Skills';
import Parralax from '../parralax/Parralax';

export default function Home() {
    document.title = "Noé Jais | Accueil";

    return (
        <div className="home">
            <div className='home-one-container'>
                {Parralax()}
            </div>
            <div className='home-two-container'>
                {Skills()}
            </div>
            <div className='home-three-container'>
                {Project()}
            </div>
            <div className='home-four-container'>
                {Contact()}
            </div>
        </div>
    );
}
