import React, { useEffect, useContext } from 'react';
import Parallax from '../../helpers/Parralax-Effect'
import { LanguageContext } from "../../components/languages/LanguageContext";

import innovation from '../../assets/pictures/black.png'
import codage from '../../assets/pictures/earth.png'
import biometrie from '../../assets/pictures/mercur.png'
import telephone from '../../assets/pictures/moon.png'
import digitale from '../../assets/pictures/red.png'
import Astronaut from '../../assets/pictures/Astronaut.png'

export default function Parralax() {

    useEffect(() => {
        Parallax();
    }, []);

    const locale = useContext(LanguageContext);
    const langue = locale.state.language;

    if (langue) {
        return (
            <div className='home-one-container'>
                <div className="home-one-container-left">
                    <div>
                        <img className="home-one-element" src={innovation} alt={"innovation"} />
                        <img className="home-one-element" src={codage} alt={"innovation"} />
                        <img className="home-one-element" src={biometrie} alt={"innovation"} />
                        <img className="home-one-element" src={telephone} alt={"innovation"} />
                        <img className="home-one-element" src={digitale} alt={"innovation"} />
                    </div>
                    <div>
                        <div className='home-one-span'>
                            <span className='home-text-span'>B</span>
                            <span className='home-text-span'>o</span>
                            <span className='home-text-span'>n</span>
                            <span className='home-text-span'>j</span>
                            <span className='home-text-span'>o</span>
                            <span className='home-text-span'>u</span>
                            <span className='home-text-span'>r</span>
                            <span className='home-text-span'>,</span>
                        </div>
                        <div className='home-one-span'>
                            <span className='home-text-span'>J</span>
                            <span className='home-text-span'>e</span>
                            <span> </span>
                            <span className='home-text-span'>s</span>
                            <span className='home-text-span'>u</span>
                            <span className='home-text-span'>i</span>
                            <span className='home-text-span'>s</span>
                            <span> </span>
                            <span className='home-text-span' style={{ "color": "var(--title-color)" }}>N</span>
                            <span className='home-text-span'>o</span>
                            <span className='home-text-span'>é</span>
                            <span className='home-text-span'>,</span>
                        </div>
                        <div className='home-one-span'>
                            <span className='home-text-span'>D</span>
                            <span className='home-text-span'>é</span>
                            <span className='home-text-span'>v</span>
                            <span className='home-text-span'>e</span>
                            <span className='home-text-span'>l</span>
                            <span className='home-text-span'>o</span>
                            <span className='home-text-span'>p</span>
                            <span className='home-text-span'>p</span>
                            <span className='home-text-span'>e</span>
                            <span className='home-text-span'>u</span>
                            <span className='home-text-span'>r</span>
                            <span> </span>
                            <span className='home-text-span'>w</span>
                            <span className='home-text-span'>e</span>
                            <span className='home-text-span'>b</span>
                        </div>
                        <p className='home-one-desc'>
                            Développeur Full-Stack / Web Designer
                        </p>
                    </div>
                    <div className='home-one-scroll'>
                        <div className='home-scroll-one'>scroll down <i className='bx bx-fw bx-down-arrow-alt' /></div>
                        <div className='home-scroll-two'>scroll down <i className='bx bx-fw bx-down-arrow-alt' /></div>
                    </div>
                </div>
                <div className='home-picture-container'>
                    <img className='home-picture' src={Astronaut} alt="Astronaut" />
                </div>
            </div>
        );
    } else {
        return (
            <div className='home-one-container'>
                <div className="home-one-container-left">
                    <div>
                        <img className="home-one-element" src={innovation} alt={"innovation"} />
                        <img className="home-one-element" src={codage} alt={"innovation"} />
                        <img className="home-one-element" src={biometrie} alt={"innovation"} />
                        <img className="home-one-element" src={telephone} alt={"innovation"} />
                        <img className="home-one-element" src={digitale} alt={"innovation"} />
                    </div>
                    <div>
                        <div className='home-one-span'>
                            <span className='home-text-span'>H</span>
                            <span className='home-text-span'>e</span>
                            <span className='home-text-span'>l</span>
                            <span className='home-text-span'>l</span>
                            <span className='home-text-span'>o</span>
                            <span className='home-text-span'>,</span>
                        </div>
                        <div className='home-one-span'>
                            <span className='home-text-span'>I</span>
                            <span className='home-text-span'>'</span>
                            <span className='home-text-span'>m</span>
                            <span> </span>
                            <span className='home-text-span' style={{ "color": "var(--title-color)" }}>N</span>
                            <span className='home-text-span'>o</span>
                            <span className='home-text-span'>é</span>
                            <span className='home-text-span'>,</span>
                        </div>
                        <div className='home-one-span'>
                            <span className='home-text-span'>w</span>
                            <span className='home-text-span'>e</span>
                            <span className='home-text-span'>b</span>
                            <span> </span>
                            <span className='home-text-span'>D</span>
                            <span className='home-text-span'>e</span>
                            <span className='home-text-span'>v</span>
                            <span className='home-text-span'>e</span>
                            <span className='home-text-span'>l</span>
                            <span className='home-text-span'>o</span>
                            <span className='home-text-span'>p</span>
                            <span className='home-text-span'>e</span>
                            <span className='home-text-span'>r</span>
                        </div>
                        <p className='home-one-desc'>
                            Full-Stack Developer / Web Designer
                        </p>
                    </div>
                    <div className='home-one-scroll'>
                        <div className='home-scroll-one'>scroll down <i className='bx bx-fw bx-down-arrow-alt' /></div>
                        <div className='home-scroll-two'>scroll down <i className='bx bx-fw bx-down-arrow-alt' /></div>
                    </div>
                </div>
                <div className='home-picture-container'>
                    <img className='home-picture' src={Astronaut} alt="Astronaut" />
                </div>
            </div>
        );
    }
}