import './about.css';
import { FormattedMessage } from "react-intl";
import noe from '../../assets/pictures/noe.png';

export default function About() {
    document.title = "Noé Jais | Profil";

    const windowOpener = (url) => {
        window.open(url, "_blank");
    };

    return (
        <div className="about">
            <div className="about-container">

                <div className="about-me">
                    <img className='about-me-photo' src={noe} alt='noe'></img>
                    <div className="about-me-contact">
                        <div className="about-me-title">CONTACT</div>
                        <div className="about-separator"></div>
                        <div className='about-me-element'><i className='bx bx-body' /> Noé Jais</div>
                        <div className='about-me-element'><i className='bx bx-envelope' /> noejais@hotmail.com</div>
                        <div className='about-me-element' ><i className='bx bxl-linkedin-square' /> Noé Jais</div>
                        <div className='about-me-element' ><i className='bx bxl-github' /> Noe-Epi2024</div>
                        <div className='about-me-element'><i className='bx bxs-map' /> Paris, France</div>
                    </div>
                    <div className="about-me-langue">
                        <div className="about-me-title">LANGUES</div>
                        <div className="about-separator"></div>
                        <div className='about-me-element'><i className='bx bx-world' /> Français</div>
                        <div className='about-me-element'><i className='bx bx-world' /> Anglais</div>
                    </div>
                </div>

                <div className="about-study">
                    <div className='about-study-container' style={{ height: '100%' }}>
                        <div className='about-study-main-title'>Education</div>
                        <div className='about-study-element'>
                            <div className='about-study-title'><FormattedMessage id={"bachelor"} description={"bachelor"} /></div>
                            <div className='about-study-desc'>Epitech Paris</div>
                            <div className='about-study-date'>2019-2022</div>
                        </div>
                        <div className='about-study-element'>
                            <div className='about-study-title'><FormattedMessage id={"exchange"} description={"exchange"} /></div>
                            <div className='about-study-desc'>Dublin City University</div>
                            <div className='about-study-date'>2022-2023</div>
                        </div>
                        <div className='about-study-element'>
                            <div className='about-study-title'><FormattedMessage id={"master"} description={"master"} /></div>
                            <div className='about-study-desc'>Epitech Paris</div>
                            <div className='about-study-date'>2022-2024</div>
                        </div>
                    </div>
                </div>

                <div className='about-technologies'>
                    <div className='about-technologies-tab' style={{ height: '100%' }}>
                        <div className='about-technologies-element' onClick={() => windowOpener('https://fr.legacy.reactjs.org')}><i className='bx bxl-react' /> React</div>
                        <div className='about-technologies-element' onClick={() => windowOpener('https://nodejs.org/fr')}><i className='bx bxl-nodejs' /> Node</div>
                        <div className='about-technologies-element' onClick={() => windowOpener('https://developer.mozilla.org/fr/docs/Web/HTML')}><i className='bx bxl-html5' /> Html</div>
                        <div className='about-technologies-element' onClick={() => windowOpener('https://developer.mozilla.org/fr/docs/Web/CSS')}><i className='bx bxl-css3' /> Css</div>
                        <div className='about-technologies-element' onClick={() => windowOpener('https://www.python.org')}><i className='bx bxl-python' /> Python</div>
                        <div className='about-technologies-element' onClick={() => windowOpener('https://en.cppreference.com/w/c/language')}><i className='bx bx-code' /> C</div>
                        <div className='about-technologies-element' onClick={() => windowOpener('https://en.cppreference.com/w')}><i className='bx bxl-c-plus-plus' /> C++</div>
                        <div className='about-technologies-element' onClick={() => windowOpener('https://fr.legacy.reactjs.org')}><i className='bx bx-code' /> C#</div>
                        <div className='about-technologies-element' onClick={() => windowOpener('https://fr.legacy.reactjs.org')}><i className='bx bx-code' /> .Net</div>
                        <div className='about-technologies-element' onClick={() => windowOpener('https://fr.legacy.reactjs.org')}><i className='bx bx-code' /> Haskell</div>
                        <div className='about-technologies-element' onClick={() => windowOpener('https://fr.legacy.reactjs.org')}><i className='bx bxl-aws' /> Aws</div>
                        <div className='about-technologies-element' onClick={() => windowOpener('https://fr.legacy.reactjs.org')}><i className='bx bxl-firebase' /> Firebase</div>
                        <div className='about-technologies-element' onClick={() => windowOpener('https://fr.legacy.reactjs.org')}><i className='bx bxl-git' /> Git</div>
                        <div className='about-technologies-element' onClick={() => windowOpener('https://fr.legacy.reactjs.org')}><i className='bx bxl-github' /> GitHub</div>
                        <div className='about-technologies-element' onClick={() => windowOpener('https://fr.legacy.reactjs.org')}><i className='bx bxl-gitlab' /> GitLab</div>
                        <div className='about-technologies-element' onClick={() => windowOpener('https://fr.legacy.reactjs.org')}><i className='bx bxl-microsoft-teams' /> Teams</div>
                        <div className='about-technologies-element' onClick={() => windowOpener('https://fr.legacy.reactjs.org')}><i className='bx bxl-discord-alt' /> Discord</div>
                        <div className='about-technologies-element' onClick={() => windowOpener('https://fr.legacy.reactjs.org')}><i className='bx bxl-trello' /> Trello</div>
                    </div>
                </div>
            </div>
        </div>
    );
}