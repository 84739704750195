import './error.css';
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import Picture from '../../assets/pictures/404.png';

export default function Error() {
    document.title = "Noé Jais | Erreur";

    return (
        <div className="error">
            <div className="error-content">
                <img src={Picture} alt="logo" className="error-logo" />
                <div className="error-title">
                    <FormattedMessage id={"error_msg"} description={"error_msg"} />
                </div>
                <Link to="/" className="error-button"><FormattedMessage id={"error_btn"} description={"error_btn"} /></Link>
            </div>
        </div>
    );
}