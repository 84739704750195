import React, { useReducer, createContext, useEffect } from "react";

const LanguageContext = createContext();

const languageReducer = (state, action) => {
  switch (action.type) {
    case "EN":
      return {
        language: false,
      };
    case "FR":
      return {
        language: true,
      };
    default:
      return state;
  }
};

function LanguageProvider(props) {
  const savedLanguage = localStorage.getItem("language");
  const initialState = {
    language: savedLanguage === "fr",
  };

  if (savedLanguage === null) {
    initialState.language = navigator.language.startsWith("fr");
  }

  const [state, dispatch] = useReducer(languageReducer, initialState);

  useEffect(() => {
    localStorage.setItem("language", state.language ? "fr" : "en");
  }, [state.language]);

  return (
    <LanguageContext.Provider value={{ state: state, dispatch: dispatch }}>
      {props.children}
    </LanguageContext.Provider>
  );
}

export { LanguageContext, LanguageProvider };
