import React, { useReducer, createContext, useEffect } from "react";

export const ThemeContext = createContext();

const themeReducer = (state, action) => {
  switch (action.type) {
    case "LIGHTMODE":
      return {
        darkMode: false,
      };
    case "DARKMODE":
      return {
        darkMode: true,
      };
    default:
      return state;
  }
};

export function ThemeProvider(props) {
  const savedTheme = localStorage.getItem("theme");
  const initialState = {
    darkMode: savedTheme === "dark",
  };

  const [state, dispatch] = useReducer(themeReducer, initialState);

  useEffect(() => {
    localStorage.setItem("theme", state.darkMode ? "dark" : "light");
  }, [state.darkMode]);

  return (
    <ThemeContext.Provider value={{ state: state, dispatch: dispatch }}>
      {props.children}
    </ThemeContext.Provider>
  );
}
