import './contact.css';
import ContactForm from '../../helpers/Contact-Form';
import { FormattedMessage } from "react-intl";

export default function Contact() {
    document.title = "Noé Jais";

    const openLinkedin = () => {
        window.open('https://www.linkedin.com/in/noéjais', "_blank");
    };

    const openGithub = () => {
        window.open('https://github.com/Noe-Epi2024', "_blank");
    };

    return (
        <div className="contact-container">
            <div className='contact-form-column'>
                <div className='contact-info-container'>
                    <div className='contact-info-block'>
                        <div className='contact-info-title'><FormattedMessage id={"contact_title"} description={"contact_title"} /> <div className="contact-info-subtitle" style={{ color: 'var(--title-color)' }}><FormattedMessage id={"contact_title_colored"} description={"contact_title_colored"} /></div></div>
                        <span>
                            <div className='contact-info-type'>
                                <i className='bx bx-sm bx-mail-send' style={{ marginRight: '8px', color: 'var(--title-color)' }} />e-mail :
                            </div>
                            <div className='contact-info-mail'>
                                noejais@hotmail.com
                            </div>
                        </span>
                        <span className='contact-info-span' style={{ display: 'flex' }}>
                            <div className='contact-info-social' onClick={() => openLinkedin()}><i className='bx bx-sm bxl-linkedin-square' /></div>
                            <div className='contact-info-social' onClick={() => openGithub()}><i className='bx bx-sm bxl-github' /></div>
                        </span>
                    </div>
                </div>
            </div>
            <div className='contact-form-column'>
                <div className='contact-form-container'>
                    <ContactForm />
                </div>
            </div>
        </div>
    );
}