import React, { useContext, useEffect, useState, useRef } from 'react';
import { ThemeContext } from "../themes/ThemeContext";
import { LanguageContext } from "../languages/LanguageContext";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import WindowSize from "../../helpers/ScreenSize";
import './topbar.css';

const menuItems = [
    {
        title: 'home',
        url: '/',
    },
    {
        title: 'skill',
        url: '/compétences'
    },
    {
        title: 'project',
        url: '/projets',
    },
    {
        title: 'profile',
        url: '/profil',
    },
    {
        title: 'contact',
        url: '/contact',
    }
];

export default function Topbar() {
    const theme = useContext(ThemeContext);
    const locale = useContext(LanguageContext);
    const darkMode = theme.state.darkMode;
    const langue = locale.state.language;
    const screenWidth = WindowSize().width;
    const [isMobile, setIsMobile] = useState(false);
    const [menuOpen, setMenuOpen] = useState(false);
    const [paramOpen, setParamOpen] = useState(false);
    const refMenu = useRef(null);
    const refParams = useRef(null);

    useEffect(() => {
        if (screenWidth <= 840) {
            setIsMobile(true);
        }
        else {
            setIsMobile(false);
        }
    }, [screenWidth, isMobile]);

    useEffect(() => {
        const handleClickOutsideDiv = (e) => {
            if (menuOpen && !refMenu.current.contains(e.target)) {
                setMenuOpen(!menuOpen);
            }
            if (paramOpen && !refParams.current.contains(e.target)) {
                setParamOpen(!paramOpen);
            }
        }
        if (isMobile && refMenu && refParams) {
            document.addEventListener("click", handleClickOutsideDiv, true)
        }
    }, [isMobile, menuOpen, paramOpen]);


    const handleLanguageChange = () => {
        if (langue)
            locale.dispatch({ type: "EN" });
        else
            locale.dispatch({ type: "FR" });
    };

    const colourButton = () => {
        if (darkMode)
            theme.dispatch({ type: "LIGHTMODE" });
        else
            theme.dispatch({ type: "DARKMODE" });
    };

    const renderMenu = () => (
        menuItems.map((menu, index) => {
            return (
                <Link className="topbar-nav" to={menu.url} key={index}>
                    <FormattedMessage
                        id={menu.title}
                        description={menu.title}
                    />
                </Link>
            );
        })
    );

    const renderParams = () => (
        <>
            <div className="topbar-langue" onClick={handleLanguageChange}>
                <i className='bx bx-planet'></i><FormattedMessage id={"language"} description={"language"} />
            </div>
            <div className="topbar-theme" onClick={() => { colourButton() }}>
                {darkMode ? <><i className='bx bx-moon bx-tada-hover' ></i><FormattedMessage id={"dark"} description={"theme"} /></> : <><i className='bx bx-sun bx-tada-hover'></i><FormattedMessage id={"light"} description={"theme"} /></>}
            </div>
        </>
    );

    const renderMobileMenu = () => (
        <div className="mobile-menu">
            <button className="mobile-menu-toggle" onClick={() => setMenuOpen(!menuOpen)}>
                {menuOpen ? <i className='bx bx-md bx-x'></i> : <i className='bx bx-md bx-menu'></i>}
            </button>
            {menuOpen && (
                <div className="mobile-menu-links">{renderMenu()}</div>
            )}
        </div>
    );

    const renderMobileParams = () => (
        <div className="mobile-params">
            <button className="mobile-menu-toggle" onClick={() => setParamOpen(!paramOpen)}>
                {paramOpen ? <i className='bx bx-md bx-cog'></i> : <i className='bx bx-md bxs-cog'></i>}
            </button>
            {paramOpen && (
                <div className="mobile-menu-links">{renderParams()}</div>
            )}
        </div>
    );

    return (
        <div className="topbar">
            <div className="topbar-left">
                <div className='topbar-logo'>NJ</div>
                <div className='topbar-logo-desc'>Web Developper</div>
            </div>
            <div className="topbar-center" ref={refMenu}>
                {isMobile ? renderMobileMenu() : renderMenu()}
            </div>
            <div ref={refParams} className="topbar-right">
                {isMobile ? renderMobileParams() : renderParams()}
            </div>
        </div>
    );
}