import './game.css';
import React from "react";
import Construction from '../../assets/pictures/construction.png'
import { FormattedMessage } from "react-intl";

export default function videoGame() {
    document.title = "Noé Jais | Game";

    return (
        <div className="game-container">
            <div className="game-constructor">
                <div className='game-title'><FormattedMessage id={"game_title"} description={"game_title"} /></div>
                <img src={Construction} alt="Construction" className='game-img' />
                <div className='game-desc'><FormattedMessage id={"game_subtitle"} description={"game_subtitle"} /></div>
            </div>
        </div>
    );
}